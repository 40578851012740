import React from 'react';

const TextArea = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <div className={`form-group ${touched[field.name] && errors[field.name] ? 'has-error' : ''}`}>
    {props.label && (
      <label htmlFor={field.name} className="col-md-4 control-label c-font-dark">
        {props.label} {props.required && <span style={{ color: 'red' }}>*</span>}
      </label>
    )}

    <div className={props.label ? 'col-md-7' : 'col-md-offset-1 col-md-10'}>
      <textarea
        type="text"
        {...field}
        {...props}
        value={field.value || ''}
        className="form-control c-square c-theme input-lg"
      />
      {touched[field.name] && errors[field.name] && <span className="help-block">{errors[field.name]}</span>}
    </div>
  </div>
);

TextArea.defaultProps = {
  rows: 8
};

export default TextArea;
