import React from 'react';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import Form from '../components/Form';
import Map from '../components/Map';

const center = { lat: 53.6369669, lng: -1.7898102 };

const mapProps = {
  options: {
    center,
    zoom: 15
  },
  onMount: map => {
    new window.google.maps.Marker({
      position: center,
      map,
      title: 'Calf Hey Ltd.'
    });
  }
};

const initialValues = {
  name: '',
  email: '',
  phone: '',
  comment: ''
};

const fields = [
  { name: 'name', label: 'Your Name', type: 'input', required: true },
  { name: 'email', label: 'Your Email', type: 'input', required: true },
  { name: 'phone', label: 'Contact Phone', type: 'input' },
  { name: 'comment', label: 'Comments', type: 'textarea', required: true }
];

const schema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  comment: Yup.string().required('Comment is required')
});

const About = () => (
  <Layout title="Contact Us">
    <div className="c-content-box c-size-md c-bg-img-top c-no-padding c-pos-relative">
      <div className="container">
        <div className="c-content-contact-1 c-opt-1">
          <div className="row" data-auto-height=".c-height">
            <div className="col-sm-8 c-desktop" />
            <div className="col-sm-4">
              <div className="c-body">
                <div className="c-section">
                  <h1 className="c-font-bold c-font-uppercase c-font-30">Calf Hey Group</h1>
                </div>
                <div className="c-section">
                  <div className="c-content-label c-font-uppercase c-font-bold c-theme-bg">Address</div>
                  <p>
                    Victoria House, Victoria Street
                    <br />
                    Lockwood, Huddersfield,
                    <br />
                    HD1 3RG
                    <br />
                    United Kingdom
                  </p>
                </div>
                <div className="c-section">
                  <div className="c-content-label c-font-uppercase c-font-bold c-theme-bg">Contacts</div>
                  <p>
                    <strong>CHD: </strong> 01484 542795
                    <br />
                    <strong>CHR: </strong> 01484 448033
                    <br />
                    <br />
                    <strong>Email: </strong>{' '}
                    <a href="mailto:sales@calf-hey.com" target="_blank" rel="noopener noreferrer">
                      sales@calf-hey.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="c-content-contact-1-gmap">
        <Map id="gmapbg" {...mapProps} height="615px" />
      </div>
    </div>

    <div className="c-content-box c-size-md c-bg-img-center c-margin-t-50">
      <div className="container c-bg-white c-border-radius">
        <div className="c-content-feedback-1 c-option-2">
          <div className="row">
            <div className="col-md-offset-2 col-md-8">
              <div className="c-contact">
                <div className="c-content-title-1">
                  <h2 className="c-font-uppercase c-font-bold c-font-dark c-font-center c-font-30">Keep in touch</h2>
                  <div className="c-line-center c-theme-bg" />
                  <p className="c-font-lowercase  c-font-dark">
                    Our helpline is always open to receive any inquiry or feedback. Please feel free to drop us an email
                    from the form below and we will get back to you as soon as we can.
                  </p>
                </div>

                <Form subject="New Message at Calf Hey" fields={fields} schema={schema} initialValues={initialValues} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default About;
