import React, { Component } from 'react';

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const name = e.target.name;
    const options = e.target.options;
    const value = [];

    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }

    this.props.form.setFieldValue(name, value);
  }

  render() {
    // Tweaks for Multiple Select
    if (this.props.multiple) {
      this.props.field.onChange = this.handleChange;
      this.props.field.value = this.props.field.value || [];
    } else {
      this.props.field.value = this.props.field.value || '';
    }

    return (
      <div
        className={`form-group ${
          this.props.form.touched[this.props.field.name] && this.props.form.errors[this.props.field.name]
            ? 'has-error'
            : ''
        }`}
      >
        {this.props.label && (
          <label htmlFor={this.props.field.name} className="col-md-4 control-label c-font-dark">
            {this.props.label} {this.props.required && <span style={{ color: 'red' }}>*</span>}
            {this.props.hint && (
              <span>
                <br />
                <i className="c-font-16">{this.props.hint}</i>
              </span>
            )}
          </label>
        )}

        <div className={this.props.label ? 'col-md-7' : ''}>
          <select
            className="form-control c-square c-theme"
            {...this.props.field}
            {...this.props}
            // value={this.props.multiple ? this.props.field.value || [] : this.props.field.value}
            // onChange={this.props.multiple ? this.handleChange : this.props.field.onChange}
          >
            {this.props.options.map((option, idx) => (
              <option key={idx} value={option.key}>
                {option.value}
              </option>
            ))}
          </select>
          {this.props.form.touched[this.props.field.name] &&
            this.props.form.errors[this.props.field.name] && (
              <span className="help-block">{this.props.form.errors[this.props.field.name]}</span>
            )}
        </div>
      </div>
    );
  }
}

Dropdown.defaultProps = {
  options: []
};

export default Dropdown;
