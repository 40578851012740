import React from 'react';

const Button = props => (
  <button className={`btn ${props.theme} c-btn-uppercase ${props.size} c-btn-bold c-btn-square `} {...props}>
    {props.title}
  </button>
);

Button.defaultProps = {
  type: 'button',
  size: 'btn-lg',
  theme: 'btn-default'
};

export default Button;
