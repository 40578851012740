import React, { Component } from 'react';
import axios from 'axios';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import Input from './Form/Input';
import Select from './Form/Dropdown';
import TextArea from './Form/TextArea';
import Button from './Form/Button';

const API_PATH = '/api/mail.php';

const Loader = () => (
  <div className="c-font-center c-margin-t-40">
    <div className="lds-facebook">
      <div />
      <div />
      <div />
    </div>
  </div>
);

class FormWrapper extends Component {
  render() {
    const { fields, schema, initialValues, subject } = this.props;

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const sender = values['email'];

          const data = this.prepareMessageData(values, fields);

          axios
            .post(API_PATH, {
              subject,
              sender,
              data
            })
            .then(result => {
              alert('Message sent successfully!');
              setSubmitting(false);
              resetForm({});
            })
            .catch(error => {
              alert(
                'Something went wrong sending the message. Please, try again in a few minutes or send us an email at sales@calf-hey.com'
              );
              setSubmitting(false);
            });
        }}
        // onSubmit={(values, { setSubmitting }) => {
        //   setTimeout(() => {
        //     alert(JSON.stringify(values, null, 2));
        //     setSubmitting(false);
        //   }, 100);
        // }}
      >
        {({ isSubmitting, handleReset }) => (
          <Form className="form-horizontal">
            {/* Spam Prevention */}
            <input type="text" id="website" name="website" />
            <input type="text" id="gender" name="gender" />
            <input type="text" id="age" name="agee" />

            {fields.map((f, idx) => {
              let component;

              switch (f.type) {
                case 'input':
                case 'number':
                  component = Input;
                  break;
                case 'textarea':
                  component = TextArea;
                  break;
                case 'select':
                  component = Select;
                  break;
                default:
                  break;
              }

              return <Field key={idx} component={component} {...f} />;
            })}

            <div className="form-group c-margin-t-40">
              <div className="col-sm-offset-4 col-md-8">
                <Button title="Submit" type="submit" disabled={isSubmitting} theme="c-theme-btn" />

                <Button title="Reset" onClick={handleReset} />
              </div>
            </div>
            {isSubmitting && <Loader />}
          </Form>
        )}
      </Formik>
    );
  }

  prepareMessageData(values, fields) {
    const data = {};

    Object.keys(values).forEach(key => {
      const field = fields.find(f => f.name === key);
      const label = field && field.label;

      let value;

      if (!values[key]) {
        value = 'N/A';
      } else if (Array.isArray(values[key])) {
        value = values[key].join('; ');
      } else {
        value = values[key];
      }

      data[label] = value;
    });

    return data;
  }
}

FormWrapper.defaultProps = {
  fields: [],
  initialValues: {},
  schema: Yup.object()
};

export default FormWrapper;
