import React from 'react';

const Input = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <div className={`form-group ${touched[field.name] && errors[field.name] ? 'has-error' : ''}`}>
    {props.label && (
      <label htmlFor={field.name} className="col-md-4 control-label c-font-dark">
        {props.label} {props.required && <span style={{ color: 'red' }}>*</span>}
        {props.hint && (
          <span>
            <br />
            <i>{props.hint}</i>
          </span>
        )}
      </label>
    )}

    <div className={props.label ? 'col-md-7' : 'col-md-offset-1 col-md-10'}>
      <input
        type="text"
        {...field}
        {...props}
        disabled={props.condition && props.condition.value !== values[props.condition.key]}
        value={field.value || ''}
        className="form-control c-square c-theme input-lg"
      />
      {touched[field.name] && errors[field.name] && <span className="help-block">{errors[field.name]}</span>}
    </div>
  </div>
);

export default Input;
